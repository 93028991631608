.line-details-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  .line-details {
    text-align: right;

    .line-id {
      font-size: 2rem;
      font-weight: 900;
    }

    .line-desc {
      font-size: 0.8em;
      font-weight: 900;
    }
  }
}

@media screen and (max-width: 600px) {
  .line-details-header {
    margin-bottom: 3rem;
  }
}

.scroll-to-stops {
  display: none;
  font-weight: 900;
  font-size: 0.8em;
  margin-top: 0.5em;
}

@media screen and (max-width: 768px) {
  .scroll-to-stops { display: block; }
}
