.favorite-badge-list {
  display: flex;
  flex-wrap: wrap;

  .favorite-badge {
    font-size: 1.2rem;
    background-color: $blue;
    color: $white;
    text-decoration: none;
    text-align: center;
    &:hover { background-color: darken($blue, 10%); }
    border-radius: 10px;
    padding: 4px 10px;
    margin: 0 10px 10px 0;

    &.orange {
      background-color: $orange;
      &:hover { background-color: darken($orange, 10%); }
    }
  }
}

@media screen and (max-width: 768px) {
  .favorite-lines {
    .favorite-badge {
      font-size: 1.5rem;
      margin: 0 20px 20px 0;
    }
  }
}
