.spinner {
  display: inline-block;
  vertical-align: inherit;
  line-height: inherit;
  box-sizing: border-box;
  border: 2px solid transparent;
  border-color: $blue $blue $blue transparent;
  border-radius: 50%;
  width: 0.9em;
  height: 0.9em;
  position: relative;
  top: 0.1em;
  margin: 0 0.3em;
  animation: linear spinner-animation 900ms infinite;
}

@keyframes spinner-animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

