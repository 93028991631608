.stops {
  min-height: 250px;
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  .stops-col { width: 50%; padding: 0.5rem; }
}

@media screen and (max-width: 650px) {
  .stops {
    display: block;
    .stops-col { width: 100% }
  }
}

.closest-stop-info {
  border: 1px solid $gray;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 0.5em;

  font-size: 0.8em;
}

.stop-feed {
  border: 1px solid $gray;
  border-radius: 10px;
  padding: 10px;

  .title { text-align: center; }

  .toggle-details-button {
    display: none;
    margin: 10px 0;
    text-align: right;

    button { font-size: 0.8rem; }
  }

  @media screen and (max-width: 768px) {
    .toggle-details-button { display: block; }
  }

  .stop-feed-row {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px dashed $gray;

    .line-title {
      display: inline-block;
    }

    .line-description-badge {
      display: inline-block;
      border-radius: 10px;
      background-color: $orange;
      color: $white;
      font-size: 0.8em;
      font-weight: 500;
      margin: 1px 3px;
      padding: 2px 10px;
    }

    .time-badge {
      display: inline-block;
      background-color: $black;
      border-radius: 10px;
      color: $white;
      font-size: 0.8em;
      font-weight: 500;
      margin: 1px 3px;
      padding: 2px 10px;
    }
  }

  .last-updated {
    text-align: right;
    font-size: 0.7rem;
    color: $gray-dark;
    font-style: italic;
    margin-top: 5px;
    &.warning { color: $warning-color; }
    &.error { color: $error-color; }
  }
}

.stops-list {
  .to-map {
    color: $orange;
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 768px) {
  .stops-list {
    .stop { padding: 1rem 0; }

    .to-map { margin-left: 1rem; }
  }
}

