.closest-stops {
  border: 1px solid $gray;
  border-radius: 10px;
  padding: 10px;

  .closest-stop-row {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px dashed $gray;
    gap: 10px;
    justify-content: space-between;

    .stop-name {
      display: inline-block;
      flex-shrink: 0;

      .stop-code {
        font-size: 0.7em;
        vertical-align: middle;
        color: $orange;
      }
    }

    .stop-line-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
    }

    .stop-line {
      display: inline-block;
      background-color: $black;
      border-radius: 10px;
      color: $white;
      font-size: 0.8em;
      font-weight: 500;
      margin: 1px 3px;
      padding: 2px 10px;
    }
  }
}

.closest-stops-dropdown {
  cursor: pointer;

  &:hover {
    color: $blue;
  }

  &.is-open:after {
    transform: rotate(-90deg);
  }

  &:after {
    content: "▼";
    display: inline-block;
    transition: transform 0.2s;
    vertical-align: middle;
    font-size: 0.8em;
  }
}
