.scroll-suggestion {
  position: fixed;
  z-index: 999;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 10px 0 10px;
  border-color: red transparent transparent transparent;
  display:block;
  right: 10px;
  bottom: 2px;

  opacity: 0;
  &.animating {
    opacity: 1;
    animation-name: bounce;
    animation-duration: 0.5s;
    animation-iteration-count: 3;
  }
}

@keyframes bounce {
  0%   { bottom: 2px; animation-timing-function: ease-out; }
  50%  { bottom: 7px; animation-timing-function: ease-in; }
  100% { bottom: 2px; animation-timing-function: ease-out; }
}
