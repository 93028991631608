a:not(.favorite-badge) {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    color: $link-focus-color;
  }
}

button.btn-link {
  font-size: 1em;
  text-align: left;
  background-color: unset;
  color: $link-color;
  outline: 0;
  border: 0;
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
    text-decoration: underline;
  }

  &:focus {
    color: $link-focus-color;
  }
}

button.btn-dark {
  cursor: pointer;
  background-color: $black;
  color: $white;
  border: 2px solid $black;
  outline: 0;
  font-weight: bolder;
  padding: 5px 10px;
  margin: 2px 8px 2px 0;
  border-radius: 1px;

  &:hover,
  &:focus {
    box-shadow: 0 0 1px 2px $shadow-color;
  }

  &.faded {
    color: $gray;
    border-color: $gray;
    background-color: $white;
    &:hover { border-color: $black; }
  }

  &:active {
    transform: translateY(1px);
  }
}


input.quick-search {
  width: 100%;
  font-size: 1.2rem;
  padding: 4px 2px;
  &.small { font-size: 0.9rem; }
}
