@mixin star-color($color) {
  ._star-graphic {
    fill: $color;
    stroke: darken($color, 10%);
  }
}

.favorite-star {
  $active-color: $blue;
  $inactive-color: $gray;

  cursor: pointer;
  font-size: 1em;
  width: 0.6em;
  height: 0.6em;
  padding: 0 0.2em;

  &.big-star { font-size: 1.5em; }

  @include star-color($inactive-color);

  &.active {
    @include star-color($active-color);
  }

  &:hover {
    @include star-color(darken($inactive-color, 10%));

    &.active {
      @include star-color(darken($active-color, 10%));
    }
  }
}
