$black: #0f0f0f;
$white: #f0f0f0;
$gray: #b4b4b4;
$gray-dark: #5a5a5a;
$blue: #4475cd;
$orange: #cd7044;

$warning-color: #d2a800;
$error-color: #ed4337;
$link-color: $blue;
$link-hover-color: darken($link-color, 10%);
$link-focus-color: darken($link-color, 20%);
$shadow-color: #b4b4cd;

$container-padding: 30px;

html {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-size: 1rem;
  margin: 20px;
  padding: 0;
  background-color: $black;
  font-family:  "Roboto", "Helvetica Neue", sans-serif;
}

@import "./line_list.scss";
@import "./line_details.scss";
@import "./stops_feed.scss";
@import "./controls.scss";
@import "./star.scss";
@import "./favorites.scss";
@import "./scroll_suggestion.scss";
@import "./spinner.scss";
@import "./closest_stops.scss";
@import '~leaflet/dist/leaflet.css';

h1 {
  font-size: 1.7rem;
  font-weight: 900;
  margin: 1.2rem 0;
}

h2 {
  font-size: 1.3rem;
  font-weight: 900;
  margin: 1.2rem 0;
  &:first-child { margin-top: 0; }
}

.title {
  font-weight: 900;
  margin-bottom: 0.5rem;
}

.container {
  background-color: $white;
  padding: $container-padding;
  border-radius: 10px;
  min-height: 90vh;
  max-width: 800px;
  margin: 0 auto;
}

.error-box {
  color: $white;
  background-color: $error-color;
  padding: 1rem;
}

.small-error {
  margin-top: 0.5rem;
  font-style: italic;
  font-size: 0.8rem;
  color: $error-color;
  text-align: right;
}

@media screen and (max-width: 768px) {
  body { margin: 0; }
  .container { border-radius: 0; }
}

@media screen and (max-width: 600px) {
  .leaflet-container {
    /* @Note Makes more room to finger-scroll without
       accidentally scrolling inside the map */
    margin-left: -#{$container-padding};
  }
}

@media screen and (max-width: 350px) {
  html { font-size: 14px; }
}
